<template>
  <!-- 套餐充值 -->
  <short-message
    :textLeft="`卡号:${userInfo.virtualId || userInfo.iccid}`"
    :background="tool.getThemeList(theme)"
    :textRight="`可用短信: ${sms.residual}条`"
  >
    <van-tabs
      v-model:active="active"
      color="#ffffff"
      title-inactive-color="#AAAAAA"
      :title-active-color="tool.getThemeList(theme)"
      @change="changeTab"
    >
      <van-tab title="发送短信">
        <div class="sendWrap">
          <div class="Sms_box">
            <div v-for="(item, index) in smsHistory" :key="index">
              <div class="desc_date">
                <div>{{ item.beginTime || "----" }}</div>
                <div
                  :class="{
                    desc_fail: item.status == 4,
                    desc_succeed: item.status <= 3
                  }"
                >
                  <span v-if="item.status == 4"> 发送失败 </span>
                  <span v-else> 发送成功 </span>
                </div>
              </div>
              <div class="desc_text_succeed" v-if="item.status <= 3">
                {{ item.msgContent }}
              </div>
              <div class="desc_fail_box" v-else>
                <div class="desc_text_fail">{{ item.msgContent }}</div>
                <div class="desc_fail_logo" @click="sendSms(item.msgContent)">
                  <img
                    alt="logo"
                    src="../../assets/image/sendSMS/icon_sendmessages_a.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="Sms_input">
            <div class="desc_input">
              <van-field
                v-model="smsText"
                autosize
                :rows="rows"
                maxlength="200"
                type="textarea"
                placeholder="输入短信内容，或选择模板"
              />
            </div>
            <div class="btns">
              <van-icon class="desc_add" name="add" @click="onClick" />
              <button class="desc_button" @click="sendSms(smsText)">发送</button>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="回调信息">
        <div class="wrap">
          <div
            class="item clearFix"
            v-for="(item, index) in smsBackLog"
            :key="index"
          >
            <div class="time">回调时间：{{ item.createTime }}</div>
            <div class="msgContent">
              {{ item.msgContent }}
            </div>
          </div>
          <div class="tip" v-if="!smsBackLog || smsBackLog.length <= 0">
            暂无回调信息~
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <van-popup v-model:show="show_notice" position="bottom">
      <van-picker
        title="选择模板"
        :columns="templateList"
        value-key="name"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
    </van-popup>
  </short-message>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onBeforeMount } from "vue";
export default {
  setup() {
    const state = reactive({
      theme: "", //主题名称
      active: 0,
      show_notice: false,
      userInfo: {},
      sms: {},
      smsText: "",
      templateList: [],
      template: {},
      smsHistory: [],
      smsBackLog: [],
      rows: 1
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $http: http, $store: store } = ctx;
    const methods = {
      onClick() {
        state.show_notice = true;
      },
      onConfirm({ content }) {
        state.smsText = content;
        state.show_notice = false;
      },
      onCancel() {
        state.show_notice = false;
      },
      onChange(value, index) {
        console.log("请选择模板");
      },
      getSms() {
        return http
          .post("/sms/getSmsInfo", {
            card: state.userInfo.iccid
          })
          .then(({ data }) => {
            if (data.code === 0) {
              Object.assign(state.sms, {
                total: data.totalSms,
                used: data.sendSms,
                residual: data.totalSms - data.sendSms
              });
              Object.assign(state.userInfo, {
                sim: data.sim
              });
              return data;
            }
            tool.toast({
              msg: data.msg
            });
          });
      },
      getTemplate() {
        return http
          .post("/sms/getSmsTemplate", {
            card: state.userInfo.iccid
          })
          .then(({ data }) => {
            if (data.code === 0) {
              state.templateList = data.templates || [];
              return data;
            }
            tool.toast({
              msg: data.msg
            });
          });
      },
      getSmsHistory() {
        http
          .post("/sms/getSmsHistory", {
            card: state.userInfo.iccid
          })
          .then(({ data }) => {
            if (data.code === 0) {
              state.smsHistory = data.historys;
              return data;
            }
            tool.toast({
              msg: data.msg
            });
          });
      },
      getSmsBackLog() {
        http
          .post("/sms/getSmsCallBack", {
            card: state.userInfo.iccid
          })
          .then(({ data }) => {
            if (data.code === 0) {
              state.smsBackLog = data.callbacks;
              return data;
            }
            tool.toast({
              msg: data.msg
            });
          });
      },
      changeTab(name, title) {
        if (title === "回调信息") {
          methods.getSmsBackLog();
        }
      },
      sendSms(smsText) {
        if (!smsText) return;
        http
          .post("/sms/sendSms", {
            content: smsText,
            sims: state.userInfo.sim
          })
          .then(({ data }) => {
            const { code, msg } = data;
            methods.getSmsHistory();
            methods.getSms();
            if (code !== 0) {
              state.smsText = "";
              return;
            }
            tool.toast({ msg });
          });
      }
    };
    onBeforeMount(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      state.userInfo = store.state.user.userInfo;
      methods.getSms();
      methods.getTemplate();
      methods.getSmsHistory();
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";

.sendWrap {
  width: 100vw;
  padding: 20px 30px;
  box-sizing: border-box;
  position: relative;
  .Sms_box {
    width: 690px;
    height: calc(100vh - 340px);
    background: #ffffff;
    border-radius: 20px;
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    .desc_date {
      width: 482px;
      height: 60px;
      font: {
        size: 22px;
        weight: 500;
      }
      color: #aaaaaa;
      padding: 20px 40px 0px 46px;
      display: flex;
      justify-content: space-between;
      .desc_succeed,
      .desc_fail {
        width: 148px;
        height: 60px;
        color: #ffffff;
        background: #2196f3;
        border-radius: 20px 20px 0px 0px;
        text-align: center;
        line-height: 60px;
      }
      .desc_fail {
        background: #fc992c;
      }
    }
    .desc_text_succeed,
    .desc_text_fail {
      width: 444px;
      background: #f1f2f3;
      border-radius: 20px;
      margin-left: 40px;
      word-break: break-all;
      font: {
        size: 26px;
        weight: 400;
      }
      color: #333333;
      padding: 20px;
      margin-bottom: 60px;
    }
    .desc_fail_box {
      display: flex;
      justify-content: space-evenly;
      width: 692px;
      align-items: center;
      .desc_text_fail {
        height: 64px;
      }
      .desc_fail_logo {
        margin-left: 20px;
        img {
          width: 32px;
          height: 33px;
        }
      }
    }
  }
  .Sms_input {
    width: 690px;
    padding: 15px 0;
    background: #ffffff;
    box-shadow: 0px -4px 10px 0px #f1f2f3;
    border-radius: 20px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    .desc_input {
      width: 450px;
      word-wrap: break-word;
      word-break: break-all;
      padding-right: 10px;
      border-right: 1px solid #efefef;
      height: auto !important;
      /deep/ .van-cell {
        padding: 10px 3px;
      }
    }

    .desc_add {
      font-size: 46px;
    }
    .desc_button {
      width: 112px;
      height: 48px;
      color: #ffffff;
      border: none;
      @include background_color("background_color1");
      box-shadow: 0px -4px 10px 0px #f1f2f3, 0px 8px 16px 0px #bce1ff;
      border-radius: 24px;
      text-align: center;
      line-height: 48px;
      font-size: 24px;
      margin-left: 15px;
    }
    .btns {
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.tip {
  width: 96%;
  padding: 10px 0;
  margin: 15px auto;
  border-radius: 10px;
  background: #fff;
  text-align: center;
  font-size: 24px;
  color: #999;
}
.wrap {
  width: 690px;
  height: calc(100vh - 240px);
  margin: 20px auto;
  border-radius: 15px;
  overflow-y: auto;
  .item {
    padding: 20px 3%;
    margin-bottom: 30px;
    .time {
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      border-radius: 10px 10px 0 0;
      background: #fcfcfc;
      display: inline-block;
      font-size: 26px;
      color: #666;
      margin-bottom: 10px;
    }
    .msgContent {
      padding: 10px 3%;
      background: #fff;
      border-radius: 0 0 10px 10px;
      font-size: 26px;
      color: #333;
    }
  }
}
</style>
